import React from 'react'
import PropTypes from 'prop-types'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

const LibraryIndex = ({ pageContext, location }) => {
    return (
        <>
            <MetaData location={location} title={pageContext.title} description={pageContext.description}/>
            <Layout pageContext={pageContext}>
                <div className="container">
                    <h1>{pageContext.title}</h1>
                    {pageContext.items.map((item) => (
                        <div>
                          <a href={`/${pageContext.pathPrefix}/${item["url_slug"]}/`.toLowerCase()}>{item["name"]}</a>
                        </div>
                    ))}
                </div>
            </Layout>
        </>
    )
}

LibraryIndex.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default LibraryIndex
